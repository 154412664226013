import repositories from '../repositories/api'

/**
 *
 * @param reduce
 * @returns {{collaborations, foodies, invitations, notifications, restaurants, session, users}}
 */
const useRepository = (reduce = () => repositories) => reduce(repositories)

export default useRepository
