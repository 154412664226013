<template>
    <div class="p-grid p-fluid">
        <div class="p-col-12">
            <div class="card">
                <DataTable
                    dataKey="id"
                    responsiveLayout="scroll"
                    filterDisplay="menu"
                    :lazy="true"
                    :rows="15"
                    :paginator="true"
                    :value="bookings.data"
                    :totalRecords="bookings.total"
                    :rowHover="true"
                    :loading="loading"
                    @page="query.page = $event.page + 1"
                    >
                    <template #header>
                        <h2>Reservaciones</h2>

                        <div class="p-d-flex p-jc-between">
                            <div class="p-d-flex p-jc-end">
                        <span class="p-input-icon-left ">
                            <i class="pi pi-search"/>
                            <InputText v-model="query.restaurant_search" placeholder="Restaurante"/>
                        </span>
                            </div>
                        </div>
                    </template>

                    <Column field="id" header="ID"></Column>
                    <Column field="slim_restaurant.name" header="Restaurante"></Column>
                    <Column header="Referidor">
                      <template #body="{ data }">
                          <p>{{ data.solo_link ? '@' + data.solo_link.foodie.ig_username : '' }}</p>
                      </template>
                    </Column>
<!--                    <Column field="client_first_name" header="Nombre"></Column>-->
<!--                    <Column field="client_last_name" header="Apellido"></Column>-->
                    <Column header="Nombre">
                        <template #body="{ data }">
                            <p>{{ data.client_first_name + ' ' + data.client_last_name }}</p>
                        </template>
                    </Column>
                    <Column field="client_phone" header="Teléfono"></Column>
                    <Column field="client_email" header="Email"></Column>
                    <Column field="time" header="Hora"></Column>
                    <Column field="date" header="Fecha"></Column>
                    <Column field="people_amount" header="Personas"></Column>
                    <Column field="solo_link_id" header="Vía">
                        <template #body="{ data }">
                            <p v-if="data.related_source === 'solo_link'" style="color: #1D9F25">Sololink</p>
                            <p v-else style="color: #D02222">Colab</p>
                        </template>
                    </Column>
<!--                    <Column header="Estatus">-->
<!--                        <template #body="{ data }">-->
<!--                            <p>{{ statuses[data.status] }}</p>-->
<!--                        </template>-->
<!--                    </Column>-->
                    <Column header="Estatus Covermanager">
                        <template #body="{ data }">
                            <p>{{ providerStatuses[data.provider_status] }}</p>
                        </template>
                    </Column>

                    <template #empty>
                        No se encontraron reservaciones
                    </template>
                    <template #loading>
                        Cargando reservaciones
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { useRepository } from '../../hooks'
// TODO PAGINAR ESTO
export default {
    name: 'index',
    setup () {
        const bookingsRepository = useRepository(({bookings}) => (bookings))
        const bookings = ref([])
        const loading = ref(false)
        const query = ref({
            'limit': 15,
            'paginate': true,
            'page': 1,
            'sort_by': 'id,desc',
            'with': 'slimRestaurant,foodie',
            'uses_covermanager': true
        })
        const statuses = ref({
            active: 'Activo',
            pending: 'Pendiente',
            confirmed: 'Confirmado',
            confirmed_by_provider: 'confirmado por proveedor',
            canceled: 'Cancelado',
            canceled_by_provider: 'Cancelado por proveedor',
        })
        const providerStatuses = ref({
            not_completed: 'No completada',
            to_review: 'A revisar',
            no_show: 'No show',
            canceled_by_the_client: 'Cancelada por el cliente',
            canceled_by_the_restaurant: 'Cancelada por el restaurante',
            pending: 'Pendiente de confirmación',
            confirmed: 'Confirmada',
            re_confirmed: 'Reconfirmada',
            seated: 'Sentada',
            arrival: 'Llegada',
            release: 'Liberada',
            bill_requested: 'Cuenta solicitada'
        })
        const searchDebounce = ref()

        const getBookings = () => {
            loading.value = true
            bookingsRepository.list(query.value)
                .then(({ data: response }) => {
                    bookings.value = response
                })
                .finally(() => {
                    loading.value = false
                })
        }
        watch(() => query.value.restaurant_search, () => {
            if (searchDebounce.value) {
                clearTimeout(searchDebounce.value)
            }

            searchDebounce.value = setTimeout(() => {
                getBookings();
            }, 600)
        })
        watch(() => query.value.page, () => {
            getBookings()
        })

        onMounted(() => {
            getBookings()
        })
        return {
            bookings,
            loading,
            query,
            statuses,
            providerStatuses
        }
    },
}
</script>

<style scoped>

</style>
